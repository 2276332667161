import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[(_vm.loggedIn)?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c(VToolbarTitle,{staticClass:"text-overline secondary--text"},[_vm._v(" Submitted requests ")]),_c('request-table',{attrs:{"resourceType":"requests","itemsPerPage":5,"hideDefaultFooter":true,"isWidget":true}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"to":"/requests","color":"primary","small":"","plain":""}},[_vm._v("View More")])],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c(VToolbarTitle,{staticClass:"text-overline secondary--text"},[_vm._v(" Awaiting your approval ")]),_c('approval-table',{attrs:{"resourceType":"approvals","itemsPerPage":5,"hideDefaultFooter":true,"isWidget":true}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"to":"/approvals","color":"primary","small":"","plain":""}},[_vm._v("View More")])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('account-table',{attrs:{"tableTitle":"Sponsored Guest Accounts","hideDefaultFooter":true,"isWidget":true}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"to":"/accounts","color":"primary","small":"","plain":""}},[_vm._v("View More")])],1)],1)],1)],1):_c(VRow,[_c(VCol,{attrs:{"cols":"12","align":"center"}},[_c(VBtn,{attrs:{"color":"primary","depressed":"","x-large":""},on:{"click":function($event){return _vm.login()}}},[_vm._v(" Login ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }