import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"elevation-0",attrs:{"fixed-header":"","headers":_vm.customHeaders ? _vm.customHeaders : _vm.headers,"items":_vm.requests,"search":_vm.search,"hide-default-footer":_vm.hideDefaultFooter,"options":_vm.options,"server-items-length":_vm.totalRequests,"footer-props":_vm.footerProps,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VSnackbar,{attrs:{"color":_vm.requestSnackbar.color,"vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.requestSnackbar.value = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.requestSnackbar.value),callback:function ($$v) {_vm.$set(_vm.requestSnackbar, "value", $$v)},expression:"requestSnackbar.value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.requestSnackbar.text)}})])]},proxy:true},{key:"item.request.rawsDocumentNumber",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'show-request',
        params: { rawsDocumentNumber: item.request.rawsDocumentNumber }
      }}},[_vm._v(_vm._s(item.request.rawsDocumentNumber.toUpperCase()))])]}},{key:"item.rawsDocumentNumber",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'show-request',
        params: { rawsDocumentNumber: item.rawsDocumentNumber }
      }}},[_vm._v(_vm._s(item.rawsDocumentNumber.toUpperCase()))])]}},{key:"item.request.requestType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRequestTypeLabel(item.request.requestType))+" ")]}},{key:"item.requestType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRequestTypeLabel(item.requestType))+" ")]}},{key:"item.request.rawsStatus",fn:function(ref){
      var item = ref.item;
return [_c('request-status-chip',{attrs:{"status":item.request.rawsStatus}})]}},{key:"item.rawsStatus",fn:function(ref){
      var item = ref.item;
return [_c('request-status-chip',{attrs:{"status":item.rawsStatus}})]}},{key:"item.request.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.request.createdAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.createdAt))+" ")]}},{key:"item.request.updatedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.request.updatedAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDateTime(item.updatedAt))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }