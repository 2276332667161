<template>
  <v-data-table
    fixed-header
    :headers="customHeaders ? customHeaders : headers"
    :items="requests"
    class="elevation-0"
    :search="search"
    :hide-default-footer="hideDefaultFooter"
    :options.sync="options"
    :server-items-length="totalRequests"
    :footer-props="footerProps"
    :loading="isLoading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-snackbar
        v-model="requestSnackbar.value"
        :color="requestSnackbar.color"
        vertical
      >
        <span v-html="requestSnackbar.text"></span>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="requestSnackbar.value = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <template v-slot:[`item.request.rawsDocumentNumber`]="{ item }">
      <router-link
        :to="{
          name: 'show-request',
          params: { rawsDocumentNumber: item.request.rawsDocumentNumber }
        }"
        >{{ item.request.rawsDocumentNumber.toUpperCase() }}</router-link
      >
    </template>
    <template v-slot:[`item.rawsDocumentNumber`]="{ item }">
      <router-link
        :to="{
          name: 'show-request',
          params: { rawsDocumentNumber: item.rawsDocumentNumber }
        }"
        >{{ item.rawsDocumentNumber.toUpperCase() }}</router-link
      >
    </template>
    <template v-slot:[`item.request.requestType`]="{ item }">
      {{ getRequestTypeLabel(item.request.requestType) }}
    </template>
    <template v-slot:[`item.requestType`]="{ item }">
      {{ getRequestTypeLabel(item.requestType) }}
    </template>
    <template v-slot:[`item.request.rawsStatus`]="{ item }">
      <request-status-chip
        :status="item.request.rawsStatus"
      ></request-status-chip>
    </template>
    <template v-slot:[`item.rawsStatus`]="{ item }">
      <request-status-chip :status="item.rawsStatus"></request-status-chip>
    </template>
    <template v-slot:[`item.request.createdAt`]="{ item }">
      {{ formatDisplayDateTime(item.request.createdAt) }}
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      {{ formatDisplayDateTime(item.createdAt) }}
    </template>
    <template v-slot:[`item.request.updatedAt`]="{ item }">
      {{ formatDisplayDateTime(item.request.updatedAt) }}
    </template>
    <template v-slot:[`item.updatedAt`]="{ item }">
      {{ formatDisplayDateTime(item.updatedAt) }}
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'pinia'
import { mapActions } from 'pinia'
import { useRequestStore } from '@/store/request'
import { useUserStore } from '@/store/user'
import FormattingMixin from '@/mixins/formatting.mixin'
import RequestMixin from '@/mixins/request.mixin'
import RequestStatusChip from '@/components/RequestStatusChip.vue'

export default {
  props: {
    customHeaders: Array,
    resourceType: String,
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    isWidget: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  mixins: [FormattingMixin, RequestMixin],
  components: { RequestStatusChip },
  name: 'RequestTable',
  data() {
    return {
      search: '',
      footerProps: { 'items-per-page-options': [5, 10, 25, 50, 100] },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      isLoading: true
    }
  },
  computed: {
    currentPath() {
      return this.$router.history.current.fullPath
    },
    items() {
      return this.requests
    },
    headers() {
      return this.isWidget ? this.requestHeadersWidget : this.requestHeaders
    },
    ...mapState(useRequestStore, [
      'requests',
      'requestHeadersWidget',
      'requestHeaders',
      'totalRequests',
      'requestSnackbar'
    ]),
    ...mapState(useUserStore, ['user'])
  },
  methods: {
    ...mapActions(useRequestStore, ['fetchRequestsAsync'])
  },
  watch: {
    async user(val) {
      if (val) {
        this.isLoading = true
        await this.fetchRequestsAsync(this.options).then((res) => {
          this.isLoading = false
        })
      }
    },
    options: {
      async handler(v) {
        this.$emit('update:options', v)
        this.isLoading = true
        if (this.user != null) {
          await this.fetchRequestsAsync(this.options).then((res) => {
            this.isLoading = false
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.itemsPerPage != this.options.itemsPerPage) {
      this.options.itemsPerPage = this.itemsPerPage
    }
  }
}
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: thin solid rgba(255, 234, 226, 0.75);
}

.theme--light.v-data-table {
  color: #192b40;
  letter-spacing: 1px;
  word-break: break-all;
}

a {
  text-decoration: none;
}
</style>
