<template>
  <v-container fluid>
    <v-row v-if="loggedIn">
      <v-col cols="6">
        <v-card outlined>
          <v-card-text>
            <v-toolbar-title class="text-overline secondary--text">
              Submitted requests
            </v-toolbar-title>
            <request-table
              resourceType="requests"
              :itemsPerPage="5"
              :hideDefaultFooter="true"
              :isWidget="true"
            ></request-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/requests" color="primary" small plain>View More</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card outlined>
          <v-card-text>
            <v-toolbar-title class="text-overline secondary--text">
              Awaiting your approval
            </v-toolbar-title>
            <approval-table
              resourceType="approvals"
              :itemsPerPage="5"
              :hideDefaultFooter="true"
              :isWidget="true"
            ></approval-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/approvals" color="primary" small plain>View More</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <account-table
              tableTitle="Sponsored Guest Accounts"
              :hideDefaultFooter="true"
              :isWidget="true"
            ></account-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/accounts" color="primary" small plain>View More</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" align="center">
        <v-btn color="primary" depressed x-large @click="login()">
          Login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/auth'
import ApprovalTable from '../components/ApprovalTable.vue'
import RequestTable from '../components/RequestTable.vue'
import AccountTable from '../components/AccountTable.vue'

export default {
  components: { ApprovalTable, RequestTable, AccountTable },
  name: 'Home',
  data() {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, ['loggedIn'])
  },
  methods: {
    ...mapActions(useAuthStore, ['login'])
  },
  created() {
    window.that = this
  }
}
</script>

<style scoped>
.theme--light.v-sheet--outlined {
  border: thin solid rgba(255, 234, 226, 0.75);
}
</style>
